
  import { Component, Vue } from "vue-property-decorator";
  import { PackageService, WechatService, OrganizationService, FamilyService, EmployerNoticeService } from "src/services";
  import { Toast, Dialog } from "vant";
  import globalConfig from "src/config/index";
  
  @Component
  export default class PackageDetail extends Vue
  {
      /**
       * 是否显示弹框
       * @private
       * @returns boolean
       */
       private isShowDialog: boolean = false;
  
       /**
       * 婚姻状态
       * @private
       * @returns  已婚 0 未婚 1
       */
       private marriageStatus: string = '';  
  
       /**
       * 获取当前登入信息
       * @private
       * @returns void
       */
       private get userInfo(): any
      {
          return this.$store.getters.userInfo || {};
      }
  
      /**
       * 确认为已婚弹窗
       * @private
       * @returns boolean
       */
       private marriedTip: boolean = false;
  
  
       /**
       * 确认为未婚弹窗
       * @private
       * @returns boolean
       */
       private showOverlay: boolean = false;
  
  
       private created () {
          this.getUserDetail()
       }

      /**
       * 获取当前来源页面人员id
       * @private
       * @returns any
       */
      protected get employerMemberId(): any
      {
          return this.$route.query.employerMemberId || null;
      }

      /**
       * 获取当前来源页面人员id
       * @private
       * @returns any
       */
      protected get questionnaireRId(): any
      {
          return this.$route.query.questionnaireRId || null;
      }
      
      /**
       * 获取当前列表类型
       * @private
       * @returns any
       */
      protected get type(): any
      {
          return this.$route.query.type || null;
      }

       /**
       * 更新为已婚
       * @private
       * @returns Promise<void>
       */
       private updateMarried(status: string) {
          this.marriedTip= false
          this.updateMarriageStatus(status)
          // 确认检前须知
          this.confirmPhysicalNotice()
      }

      /**
       * 更新为未婚
       * @private
       * @returns Promise<void>
       */
       private updateUnMarried(status: string) {
          this.showOverlay= false
          this.updateMarriageStatus(status)
          // 确认检前须知
          this.confirmPhysicalNotice()
      }
  
       /**
       * 获取用户详情
       * @private
       * @returns Promise<void>
       */
       private async getUserDetail(): Promise<void>
      {
          try
          {
              let {content: result} =  await FamilyService.instance.getFamilyDetail(this.userInfo.userId);
              if(result.data)
              {
                  this.marriageStatus = result.data.marriageStatus
              }
          }
          catch(err)
          {
              Toast(err);
          }
      }


      /**
       * 修改婚姻状态
       * @private
       * @returns Promise<void>
       */
      private async updateMarriageStatus(status): Promise<void>
      {
          try
          {
            await EmployerNoticeService.instance.updateMarriageStatus(status);
            Toast('修改成功');
            // 判断是跳转普通套餐列表页还是定制套餐列表页
            if (this.questionnaireRId) {
              this.$router.push({name:'cuspackage',query:{ employerMemberId: this.employerMemberId, questionnaireRId: this.questionnaireRId }});
            }
            else {
              this.$router.push({ name: "physical-package", query: { employerMemberId: this.employerMemberId ? this.employerMemberId : '', type: this.type ? this.type : '1' } });
            }
          }
          catch(err)
          {
              Toast(err);
          }
      }
  
      /**
       * 确认已婚
       * @private
       * @returns boolean
       */
       private confirmMarried (status) {
          // 确认是否一致
          if(status == this.marriageStatus) {
            // 判断是跳转普通套餐列表页还是定制套餐列表页
            if (this.questionnaireRId) {
              this.$router.push({name:'cuspackage',query:{ employerMemberId: this.employerMemberId ? this.employerMemberId : '', questionnaireRId: this.questionnaireRId ? this.questionnaireRId : '' }});
            }
            else {
              this.$router.push({ name: "physical-package", query: { employerMemberId: this.employerMemberId ? this.employerMemberId : '', type: this.type ? this.type : '1' } });
            }
            // 确认检前须知
            this.confirmPhysicalNotice()

          } else {
            this.marriedTip = true
          }
      }
  
  
      /**
       * 确认未婚
       * @private
       * @returns boolean
       */
       private confirmUnMarried(status) {
          // 确认是否一致
          if(status == this.marriageStatus) {
            // 判断是跳转普通套餐列表页还是定制套餐列表页
            if (this.questionnaireRId) {
              this.$router.push({name:'cuspackage',query:{ employerMemberId: this.employerMemberId, questionnaireRId: this.questionnaireRId }});
            }
            else {
              this.$router.push({ name: "physical-package", query: { employerMemberId: this.employerMemberId ? this.employerMemberId : '', type: this.type ? this.type : '1' } });
            }
            // 确认检前须知
            this.confirmPhysicalNotice()
          } else {
            this.showOverlay = true
          }
       }
  

       /**
       * 确认检前须知
       * @private
       * @returns boolean
       */
       private async confirmPhysicalNotice() {
          try {
            await EmployerNoticeService.instance.confirmPhysicalNotice()
          } catch (error) {
            Toast(error)
          }
       }

      
      /**
       * 获取企业参数配置
       * @private
       * @returns any
       */
      private get generalSettings(): any
      {
          let generalSettingsValue = localStorage.getItem("generalSettings");
          if(generalSettingsValue)
          {
              return JSON.parse(generalSettingsValue);
          }
          return [];
      }
  
      /**
       * 获取当前登入企业相关配置
       * @private
       * @returns void
       */
       protected get employerConfig(): any
      {
          let appid = localStorage.getItem("appid");
  
          let employerConfig = globalConfig.employerConfig[appid];
  
          return employerConfig;
      }
  
  
      
      
  
  }
  