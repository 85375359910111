import { render, staticRenderFns } from "./package-detail.vue?vue&type=template&id=bc82c674&scoped=true"
import script from "./package-detail.vue?vue&type=script&lang=ts"
export * from "./package-detail.vue?vue&type=script&lang=ts"
import style0 from "./package-detail.vue?vue&type=style&index=0&id=bc82c674&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc82c674",
  null
  
)

export default component.exports